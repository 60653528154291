import { Button, Card, Input, Space, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { handleSPOE } from '../Shared/Functions';

const SCTable = ({loading, serviceCoordinators, highlighted, handleRowOnClick}) => {
    const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

    const columns = [
        {
            title: "Preferred Name",
            key: "preferredName",
            dataIndex: "preferredName",
            ...getColumnSearchProps('preferredName'),
            sorter: (a, b) => a.preferredName.localCompare(b.preferredName),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Last Name",
            key: "lastName",
            dataIndex: "lastName",
            ...getColumnSearchProps('lastName'),
            sorter: (a, b) => a.lastName.localCompare(b.lastName),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "SPOE",
            key: "spoe",
            dataIndex: "spoe",
            ...getColumnSearchProps('spoe'),
            sorter: (a, b) => a.spoe.localCompare(b.spoe),
            sortDirections: ['descend', 'ascend'],
            render: handleSPOE
        },
        {
            title: "Email",
            key: "emailAddress",
            dataIndex: "emailAddress",
            ...getColumnSearchProps('emailAddress'),
            sorter: (a, b) => a.emailAddress.localCompare(b.emailAddress),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Phone Number",
            key: "phoneNumber",
            dataIndex: "phoneNumber",
            ...getColumnSearchProps('phoneNumber'),
            sorter: (a, b) => a.phoneNumber.localCompare(b.phoneNumber),
            sortDirections: ['descend', 'ascend'],
        },
    ];

    return (
        <Card style={{ height: "100%" }} size="small">
            <Table 
                pagination={false}
                loading={loading}
                dataSource={serviceCoordinators}
                columns={columns}
                rowKey={(record) => record.firstStepsId}
                rowClassName={record => (highlighted === record ? 'ant-table-row-selected' : '')}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: e => handleRowOnClick(record)
                    }
                }}
                scroll={{
                    x: 2000,
                    y: 500
                }}
            />
        </Card>
    );
};

export default SCTable;