import React from 'react';
import { Card, Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { inputSize } from '../../Shared/UI/jsx';

const { Item } = Form;
const { Option } = Select;

const PersonalAndAddress = ({ form, onBlurChange }) => {
    return(
        <Card size="small" className='formCard'>
            <Form
                form={form}
                size="small"
                layout="vertical"
                onBlur={e => {
                    if (e.currentTarget !== e.target) onBlurChange(e.target.id, e.target.value)
                }}
            >
                <Row>
                    <Col span={8}>
                        <Item name="firstStepsId" label="First Steps ID">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="iFSPDate" label="IFSP Date">
                            <DatePicker format="MM/DD/YYYY" style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}/>
                    <Col span={8}>
                        <Item name="firstName1" label="First Name">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="firstName2" label="First Name 2">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="preferredName" label="Preferred Name">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="lastName1" label="Last Name">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="lastName2" label="Last Name 2">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="lastNameHyphenated" valuePropName="checked">
                            <Checkbox>
                                Last Name Hyphenated?
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="county" label="County">
                         <Select 
                             style={inputSize}
                             showSearch
                             filterOption={(input, option) => 
                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                             }
                         >
                             <Option value="0">Brown</Option>
                             <Option value="1">Hancock</Option>
                             <Option value="2">Johnson</Option>
                             <Option value="3">Lawrence</Option>
                             <Option value="4">Marion</Option>
                             <Option value="5">Monroe</Option>
                             <Option value="6">Morgan</Option>
                             <Option value="7">Shelby</Option>
                         </Select>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="dateOfBirth" label="Date of Birth">
                            <DatePicker format="MM/DD/YYYY" style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="ageOut" label="Age Out Date">
                            <DatePicker format="MM/DD/YYYY" style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="address" label="Address">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="city" label="City">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="zipcode" label="Zipcode">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default PersonalAndAddress;