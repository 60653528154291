import AxiosInstance from "./AxiosInstance";

export const GetClients = () => {
    const url = `/Clients/GetClients`;
    return AxiosInstance.get(url);
}

export const GetOneClient = (id) => {
    const url = `/Clients/GetClientById/${id}`;
    return AxiosInstance.get(url);
}

export const EditClient = (id, data) => {
    const url = `/Clients/PutClient?id=${id}`;
    return AxiosInstance.put(url, data);
}

export const CreateClient = (data) => {
    const url = `/Clients/PostClient`;
    return AxiosInstance.post(url, data);
}

export const GetTherapists = () => {
    const url = `/Therapists/GetTherapists`;
    return AxiosInstance.get(url);
}

export const GetPhysicalTherapists = () => {
    const url = `/Therapists/GetPhysicalTherapists`;
    return AxiosInstance.get(url);
}

export const GetPhysicalTherapistAssistants = () => {
    const url = `/Therapists/GetPhysicalTherapistAssistants`;
    return AxiosInstance.get(url);
}

export const GetOccupationalTherapists = () => {
    const url = `/Therapists/GetOccupationalTherapists`;
    return AxiosInstance.get(url);
}

export const GetCertifiedOccupationalTherapistAssisstants = () => {
    const url = `/Therapists/GetCertifiedOccupationalTherapistAssisstants`;
    return AxiosInstance.get(url);
}

export const GetDevelopmentalTherapists = () => {
    const url = `/Therapists/GetDevelopmentalTherapists`;
    return AxiosInstance.get(url);
}

export const GetDevelopmentalTherapistAssistants = () => {
    const url = `/Therapists/GetDevelopmentalTherapistAssistants`;
    return AxiosInstance.get(url);
}

export const GetSpeechLanguagePathologists = () => {
    const url = `/Therapists/GetSpeechLanguagePathologists`;
    return AxiosInstance.get(url);
}

export const GetSpeechLanguagePathologistsClinicalFellowship = () => {
    const url = `/Therapists/GetSpeechLanguagePathologistsClinicalFellowship`;
    return AxiosInstance.get(url);
}

export const GetRegisteredNurses = () => {
    const url = `/Therapists/GetRegisteredNurses`;
    return AxiosInstance.get(url);
}

export const GetBehavioralists = () => {
    const url = `/Therapists/GetBehavioralists`;
    return AxiosInstance.get(url);
}

export const GetPsychologist = () => {
    const url = `/Therapists/GetPsychologist`;
    return AxiosInstance.get(url);
}

export const GetStudents = () => {
    const url = `/Therapists/GetStudents`;
    return AxiosInstance.get(url);
}

export const GetTherapistByID = (id) => {
    const url = `/Therapists/GetTherapistByID/${id}`;
    return AxiosInstance.get(url);
}

export const EditTherapist = (id, data) => {
    const url = `/Therapists/PutTherapist?id=${id}`;
    return AxiosInstance.put(url, data);
}

export const CreateTherapist = (data) => {
    const url = `/Therapists/PostTherapist`;
    return AxiosInstance.post(url, data);
}

export const InactivateTherapist = (id) => {
    const url = `/Therapists/InactivateTherapist/${id}`;
    return AxiosInstance.patch(url)
}

export const ActivateTherapist = (id) => {
    const url = `/Therapists/ActivateTherapist/${id}`;
    return AxiosInstance.patch(url)
}

export const GetServiceCoordinators = () => {
    const url = `/ServiceCoordinators/GetServiceCoordinators`;
    return AxiosInstance.get(url);
}

export const GetServiceCoordinatorById = () => {
    const url = `/ServiceCoordinators/GetServiceCoordinatorById`;
    return AxiosInstance.get(url);
}

export const EditServiceCoordinator = (id, data) => {
    const url = `/ServiceCoordinators/PutServiceCoordinator?id=${id}`;
    return AxiosInstance.put(url, data);
}

export const CreateServiceCoordinator = (data) => {
    const url = `/ServiceCoordinators/PostServiceCoordinator`;
    return AxiosInstance.post(url, data);
}