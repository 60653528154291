import React from 'react';
import { Card, Checkbox, Col, Form, Row } from 'antd';

const { Item } = Form;

const Languages = ({ form }) => {

    return(
        <Card size="small" title="Languages" className='formCard'>
            <Form
                form={form}
                size="small"
                layout="vertical"
            >
                <Row>
                    <Col span={8}>
                        <Item name="burmese" valuePropName="checked">
                            <Checkbox>
                                Burmese
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="chin" valuePropName="checked">
                            <Checkbox>
                                Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="falamChin" valuePropName="checked">
                            <Checkbox>
                                Falam Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="hakhaChin" valuePropName="checked">
                            <Checkbox>
                                Hakha Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="matuChin" valuePropName="checked">
                            <Checkbox>
                                Matu Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="tedimChin" valuePropName="checked">
                            <Checkbox>
                                Tedim Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="zanniatChin" valuePropName="checked">
                            <Checkbox>
                                Zanniat Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="zopheiChin" valuePropName="checked">
                            <Checkbox>
                                Zophie Chin
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="crioulo" valuePropName="checked">
                            <Checkbox>
                                Crioulo
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="filipino" valuePropName="checked">
                            <Checkbox>
                                Filipino
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="french" valuePropName="checked">
                            <Checkbox>
                                French
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="frenchCajun" valuePropName="checked">
                            <Checkbox>
                                French Cajun
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="frenchCanadian" valuePropName="checked">
                            <Checkbox>
                                French Canadian
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="haitainCreole" valuePropName="checked">
                            <Checkbox>
                                Haitain Creolo
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="german" valuePropName="checked">
                            <Checkbox>
                                German
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="punjabi" valuePropName="checked">
                            <Checkbox>
                                Punjabi
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="spanish" valuePropName="checked">
                            <Checkbox>
                                Spanish
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="vietnamese" valuePropName="checked">
                            <Checkbox>
                                Vietnamese
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="somali" valuePropName="checked">
                            <Checkbox>
                                Somali
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="swahili" valuePropName="checked">
                            <Checkbox>
                                Swahili
                            </Checkbox>
                        </Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default Languages;