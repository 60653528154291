import React, { useState } from 'react';
import { Card, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import { inputSize, textInputSize } from '../../Shared/UI/jsx';
import { SearchOutlined } from '@ant-design/icons';
import { serviceCoordinatorColumns } from '../../Shared/Consts/TableColumns';
import ServiceTable from '../../Shared/UI/SelectTables/ServiceCoordinator';
import { FormDescription } from '../../Shared/Consts/FormDescription';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const Services = ({ form, formContext, setFormContext }) => {
    const [serviceCoordinatorVisible, setServiceCoordinatorVisible] = useState(false);

    const handleSubmit = record => {
        setFormContext({...formContext, SC: record});
        form.setFieldsValue({serviceCoordinatorId: record.id});
    }

    return (
        <Form
            form={form}
            size="small"
            layout="vertical"
        >
            <Card size='small' className='formCard'>

                <Row>
                    <Col span={12}>
                        <Item name="referralStatus" label="Referral Status">
                            <Select
                                style={inputSize}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="0">Open</Option>
                                <Option value="1">Completed</Option>
                                <Option value="2">Partial</Option>
                            </Select>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="serviceCoordinatorId" label="Service Coordinator">
                            <Input
                                style={inputSize}
                                addonAfter={
                                    <>
                                        <SearchOutlined onClick={() => setServiceCoordinatorVisible(!serviceCoordinatorVisible)} />
                                        <ServiceTable visibility={serviceCoordinatorVisible} setVisibility={setServiceCoordinatorVisible} handleSubmit={handleSubmit} />
                                    </>
                                }
                            />
                        </Item>
                        {formContext && formContext.SC ? (
                            <FormDescription description={formContext.SC.preferredName + " " + formContext.SC.lastName} />
                        ) : null}
                    </Col>
                    <Col span={12}>
                        <Item name="serviceCoordinatorNotes" label="Service Coordinator Notes">
                            <TextArea style={textInputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="virtualVisit" valuePropName="checked">
                            <Checkbox>
                                Virtual Visit?
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="dTConsult" label="Developmental Therapy Consult">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="oTConsult" label="Occupational Therapy Consult">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="pTConsult" label="Physical Therapy Consult">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="sTConsult" label="Speech Therapy Consult">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="dTNeeded" valuePropName="checked">
                            <Checkbox>
                                Developmental Therapy Needed
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="dTFulfilled" valuePropName="checked">
                            <Checkbox>
                                Developmental Therapy Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="oTNeeded" valuePropName="checked">
                            <Checkbox>
                                Occupational Therapy Needed
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="oTFulfilled" valuePropName="checked">
                            <Checkbox>
                                Occupational Therapy Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="pTNeeded" valuePropName="checked">
                            <Checkbox>
                                Physical Therapy Needed
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="pTFulfilled" valuePropName="checked">
                            <Checkbox>
                                Physical Therapy Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="sTNeeded" valuePropName="checked">
                            <Checkbox>
                                Speech Therapy Needed
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="sTFulfilled" valuePropName="checked">
                            <Checkbox>
                                Speech Therapy Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="dTAFulfilled" valuePropName="checked">
                            <Checkbox>
                                DTA Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="cOTAFulfilled" valuePropName="checked">
                            <Checkbox>
                                COTA Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="pTAFulfilled" valuePropName="checked">
                            <Checkbox>
                                PTA Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="sLPCFFulfilled" valuePropName="checked">
                            <Checkbox>
                                SLPCF Fulfilled
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="initialReferralDate" label="Initial Referral">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="consultReferral1Date" label="Consult Referral">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="consultReferral2Date" label="Consult Referral 2">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="consultReferral3Date" label="Consult Referral 3">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="consultReferral4Date" label="Consult Referral 4">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="ongoingReferral1Date" label="Ongoing Referral">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="ongoingReferral2Date" label="Ongoing Referral 2">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="ongoingReferral3Date" label="Ongoing Referral 3">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="ongoingReferral4Date" label="Ongoing Referral 4">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="addonReferral1Date" label="Addon Referral">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="addonReferral2Date" label="Addon Referral 2">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="addonReferral3Date" label="Addon Referral 3">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="addonReferral4Date" label="Addon Referral 4">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="caseManagementNotes" label="Case Management Notes">
                            <TextArea style={textInputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalDate1" label="Supplemental Date">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalService1" label="Supplemental Service">
                            <Select
                                style={inputSize}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="0">None</Option>
                                <Option value="1">Physical Therapist</Option>
                                <Option value="2">Physical Therapist Assistant</Option>
                                <Option value="3">Occupational Therapist</Option>
                                <Option value="4">Certified Occupational Therapy Assistant</Option>
                                <Option value="5">Developmental Therapist</Option>
                                <Option value="6">Developmental Therapy Assistant</Option>
                                <Option value="7">Speech-Language Pathologist</Option>
                                <Option value="8">Speech-Language Pathologist Clinical Fellowship</Option>
                                <Option value="9">Registered Nurse</Option>
                                <Option value="10">Social Worker</Option>
                                <Option value="11">Behavioralist</Option>
                                <Option value="12">Psychologist</Option>
                                <Option value="13">Student</Option>
                            </Select>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalDate2" label="Supplemental Date 2">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalService2" label="Supplemental Service 2">
                            <Select
                                style={inputSize}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="0">None</Option>
                                <Option value="1">Physical Therapist</Option>
                                <Option value="2">Physical Therapist Assistant</Option>
                                <Option value="3">Occupational Therapist</Option>
                                <Option value="4">Certified Occupational Therapy Assistant</Option>
                                <Option value="5">Developmental Therapist</Option>
                                <Option value="6">Developmental Therapy Assistant</Option>
                                <Option value="7">Speech-Language Pathologist</Option>
                                <Option value="8">Speech-Language Pathologist Clinical Fellowship</Option>
                                <Option value="9">Registered Nurse</Option>
                                <Option value="10">Social Worker</Option>
                                <Option value="11">Behavioralist</Option>
                                <Option value="12">Psychologist</Option>
                                <Option value="13">Student</Option>
                            </Select>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalDate3" label="Supplemental Date 3">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalService3" label="Supplemental Service 3">
                            <Select
                                style={inputSize}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="0">None</Option>
                                <Option value="1">Physical Therapist</Option>
                                <Option value="2">Physical Therapist Assistant</Option>
                                <Option value="3">Occupational Therapist</Option>
                                <Option value="4">Certified Occupational Therapy Assistant</Option>
                                <Option value="5">Developmental Therapist</Option>
                                <Option value="6">Developmental Therapy Assistant</Option>
                                <Option value="7">Speech-Language Pathologist</Option>
                                <Option value="8">Speech-Language Pathologist Clinical Fellowship</Option>
                                <Option value="9">Registered Nurse</Option>
                                <Option value="10">Social Worker</Option>
                                <Option value="11">Behavioralist</Option>
                                <Option value="12">Psychologist</Option>
                                <Option value="13">Student</Option>
                            </Select>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalDate4" label="Supplemental Date 4">
                            <DatePicker format="MM/DD/YYYY" style={inputSize} />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="supplementalService4" label="Supplemental Service 4">
                            <Select
                                style={inputSize}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="0">None</Option>
                                <Option value="1">Physical Therapist</Option>
                                <Option value="2">Physical Therapist Assistant</Option>
                                <Option value="3">Occupational Therapist</Option>
                                <Option value="4">Certified Occupational Therapy Assistant</Option>
                                <Option value="5">Developmental Therapist</Option>
                                <Option value="6">Developmental Therapy Assistant</Option>
                                <Option value="7">Speech-Language Pathologist</Option>
                                <Option value="8">Speech-Language Pathologist Clinical Fellowship</Option>
                                <Option value="9">Registered Nurse</Option>
                                <Option value="10">Social Worker</Option>
                                <Option value="11">Behavioralist</Option>
                                <Option value="12">Psychologist</Option>
                                <Option value="13">Student</Option>
                            </Select>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="doctorSignature" label="Doctor's Signature">
                            <Input style={inputSize} />
                        </Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    );
}

export default Services;