import React, { useState } from 'react';
import { Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { inputSize, textInputSize } from '../../Shared/UI/jsx';
import { SearchOutlined } from '@ant-design/icons';
import ServiceTable from '../../Shared/UI/SelectTables/ServiceCoordinator';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const ServiceCoordinatorFormContent = ({ form, onBlurChange }) => {
    const [supervisorVisible, setSupervisorVisible] = useState(false);

    return (
        <Form
            form={form}
            size="small"
            layout="vertical"
            onBlur={e => {
                if (e.currentTarget !== e.target) onBlurChange(e.target.id, e.target.value)
            }}
        >
            <Row gutter={[8, 8]}>
                <Card size='small' title="Personal Info" className='formCard'>
                    <Row>

                        <Col span={8}>
                            <Item name="firstName" label="First Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="preferredName" label="Preferred Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="lastName" label="Last Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="spoe" label="SPOE">
                                <Select
                                    style={inputSize}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="0">J</Option>
                                    <Option value="1">G</Option>
                                </Select>
                            </Item>
                        </Col>
                        <Col span={8}>
                        <Item name="supervisorId" label="Supervisor">
                            <Input
                                style={inputSize}
                                addonAfter={
                                    <>
                                        <SearchOutlined onClick={() => setSupervisorVisible(!supervisorVisible)} />
                                        <ServiceTable visibility={supervisorVisible} setVisibility={setSupervisorVisible} />
                                    </>
                                }
                            />
                        </Item>
                    </Col>
                        <Col span={8}>
                            <Item name="emailAddress" label="Email Address">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="phoneNumber" label="Phone Number">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                    </Row>
                </Card>
                <Card size='small' title="Languages" className='formCard'>
                    <Row>
                        <Col span={8}>
                            <Item name="burmese" valuePropName="checked">
                                <Checkbox>
                                    Burmese
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="chin" valuePropName="checked">
                                <Checkbox>
                                    Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="falamChin" valuePropName="checked">
                                <Checkbox>
                                    Falam Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="hakhaChin" valuePropName="checked">
                                <Checkbox>
                                    Hakha Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="matuChin" valuePropName="checked">
                                <Checkbox>
                                    Matu Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="tedimChin" valuePropName="checked">
                                <Checkbox>
                                    Tedim Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="zanniatChin" valuePropName="checked">
                                <Checkbox>
                                    Zanniat Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="zopheiChin" valuePropName="checked">
                                <Checkbox>
                                    Zophie Chin
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="crioulo" valuePropName="checked">
                                <Checkbox>
                                    Crioulo
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="filipino" valuePropName="checked">
                                <Checkbox>
                                    Filipino
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="french" valuePropName="checked">
                                <Checkbox>
                                    French
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="frenchCajun" valuePropName="checked">
                                <Checkbox>
                                    French Cajun
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="frenchCanadian" valuePropName="checked">
                                <Checkbox>
                                    French Canadian
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="haitainCreole" valuePropName="checked">
                                <Checkbox>
                                    Haitain Creolo
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="german" valuePropName="checked">
                                <Checkbox>
                                    German
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="punjabi" valuePropName="checked">
                                <Checkbox>
                                    Punjabi
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="spanish" valuePropName="checked">
                                <Checkbox>
                                    Spanish
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="vietnamese" valuePropName="checked">
                                <Checkbox>
                                    Vietnamese
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="somali" valuePropName="checked">
                                <Checkbox>
                                    Somali
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="swahili" valuePropName="checked">
                                <Checkbox>
                                    Swahili
                                </Checkbox>
                            </Item>
                        </Col>
                    </Row>
                </Card>
                <Card size='small' title="Notes" className='formCard'>
                    <Row>
                        <Col span={24}>
                            <Item name="notes" label="Notes">
                                <TextArea className='textBox' />
                            </Item>
                        </Col>
                    </Row>
                </Card>
            </Row>
        </Form>
    );
}

export default ServiceCoordinatorFormContent;